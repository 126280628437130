import api from '@/api'
import { BASE_URL } from '@/urlConfig'

export default {
	namespaced: true,
	state: {
		isLoading: false,
		data: [],
	},
	mutations: {
		setIsLoading(state, payload) {
			state.isLoading = payload
		},
		setData(state, payload) {
			state.data = payload
		},
	},
	actions: {
		async getProject(context) {
			if (context.state.data.length) {
				return
			}
			context.commit('setIsLoading', true)
			const resp = await api.getProject().then(({ data }) => {
				// return data.map((it) => {
				// 	return {
				// 		...it,
				// 		thumb: BASE_URL + it.thumb,
				// 	}
				// })
				return data
			})
			context.commit('setData', resp)
			context.commit('setIsLoading', false)
			return resp
		},
	},
}
