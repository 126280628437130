<!--
 * @Descripttion: 
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-14 10:47:32
 * @LastEditors: wy
 * @LastEditTime: 2023-07-17 17:42:41
-->
<template>
    <div class="Menu-container">
        <RouterLink
            v-for="menu in menus"
            :key="menu.id"
            :to="{ name: menu.name }"
            :exact="menu.exact"
            active-class="selected"
            exact-active-class=""
        >
            <Icont :icont="menu.icont"></Icont>
            <span>{{ menu.title }}</span>
        </RouterLink>
    </div>
</template>

<script>
import Icont from '@/components/Icont.vue'

export default {
    components: {
        Icont,
    },
    data() {
        return {
            menus: [
                {
                    id: 1,
                    icont: 'house',
                    title: '首页',
                    name: 'Home',
                    exact: true,
                },
                {
                    id: 2,
                    icont: 'pen',
                    title: '文章',
                    name: 'Blog',
                    exact: false,
                },
                {
                    id: 3,
                    icont: 'info',
                    title: '关于我',
                    name: 'About',
                    exact: true,
                },
                {
                    id: 4,
                    icont: 'project',
                    title: '项目&效果',
                    name: 'Project',
                    exact: true,
                },
                {
                    id: 5,
                    icont: 'messageBoard',
                    title: '留言板',
                    name: 'Message',
                    exact: true,
                },
            ],
        }
    },
}
</script>

<style lang="less" scoped>
@import url('~@/styles/MainColor.less');

.Menu-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 10px;

    a {
        padding: 5px 20px;
        cursor: pointer;

        .iconfont {
            margin-right: 10px;
        }

        &.selected {
            background: darken(@words, 1%);
        }
    }
}
</style>
