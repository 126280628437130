/*
 * @Descripttion:
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-17 16:19:07
 * @LastEditors: wy
 * @LastEditTime: 2023-07-17 17:42:21
 */

import ErrorAddress from '@/views/ErrorAddress'

import { start, done, configure } from 'nprogress'
import 'nprogress/nprogress.css'

//模拟异步返回延迟时间
function delay(duration) {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve()
		}, duration)
	})
}
//进度条 配置项
configure({
	easing: 'ease', //使用缓和（CSS缓和字符串）调整动画设置。（默认值：ease）
	speed: 200, //速度（以毫秒为单位）调整动画设置。（默认值：200）
	minimum: 0.08, //更改启动时使用的最小百分比。（默认值：0.08）
	trickle: true, //通过将其设置为false来关闭自动递增行为。（默认值：true）
	trickleRate: 0.02, //诶次滴流增加量
	trickleSpeed: 20, //滴流频率，以毫秒为单位
	showSpinner: false, //是否显示加载ICO,默认为true
	parent: '#app', //指定父容器，默认为body
})

function getComponent(oneComponentResolve) {
	return async () => {
		start()
		//判断环境。如果是开发环境，就延时
		if (process.env.NODE_ENV === 'development') {
			delay(2000)
		}
		const resp = await oneComponentResolve()
		done()
		return resp
	}
}

export default //路由匹配规则
[
	{
		name: 'Home',
		path: '/',
		component: getComponent(() => import('@/views/home')),
		meta: { title: '首页' },
	},
	{
		name: 'About',
		path: '/about',
		component: getComponent(() => import('@/views/about')),
		meta: { title: '关于我' },
	},
	{
		name: 'Blog',
		path: '/blog',
		component: getComponent(() => import('@/views/blog')),
		meta: { title: '文章列表' },
	},
	{
		name: 'CategoryBlog',
		path: '/blog/cate/:CategoryID',
		component: getComponent(() => import('@/views/blog')),
		meta: { title: '文章' },
	},
	{
		name: 'BlogDetails',
		path: '/blog/details/:id',
		component: getComponent(() => import('@/views/blog/BlogDetails')),
		meta: { title: '文章详情' },
	},
	{
		name: 'Message',
		path: '/message',
		component: getComponent(() => import('@/views/message')),
		meta: { title: '留言板' },
	},
	{
		name: 'Project',
		path: '/project',
		component: getComponent(() => import('@/views/project')),
		meta: { title: '项目&效果' },
	},
	{
		name: 'NotFound',
		path: '*',
		component: ErrorAddress,
	},
]
