/*
 * @Descripttion:
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-19 17:09:58
 * @LastEditors: wy
 * @LastEditTime: 2023-07-20 15:12:50
 */

// 响应拦截器
import response from './interceptors/response'
// 请求拦截器
import request from './interceptors/request'

//  1-获取全局设置
async function getSetting() {
	return await response.get('/api/setting')
}

//  2-获取所有博客分类
async function getBlogtype() {
	return await response.get('/api/blogtype')
}

//  3-分页获取博客
async function getBlog(params) {
	return response({
		url: '/api/blog',
		method: 'get',
		params: {
			page: 1,
			limit: 10,
			categoryid: -1,
			...params,
		},
	})
}

//  4-获取单个博客
async function getBlogId(id) {
	return await response.get(`/api/blog/${id}`)
}

/**
 * 5-提交评论
 * @param {*} body body: {
	nickname: "昵称",
	content: "评论内容，纯文本",
	blogId: <id>	#评论的博客id
}
 * @returns Promise
 */
async function postComment(data) {
	return await response({
		url: '/api/comment',
		method: 'post',
		data,
	})
}

//  6-分页获取评论

async function getComment(params) {
	return await response.get('/api/comment', {
		params: {
			page: 1,
			limit: 10,
			blogid: -1,
			keyword: '',
			...params,
		},
	})
}

//  7-获取首页标语
async function getBanners() {
	return await response.get('/api/banner')
}

//  8-获取【关于我】页面
async function getAbout() {
	return await response.get('/api/about')
}

//  9-提交留言
async function postMessage(data) {
	return await response.post('/api/message', data)
}

//  10-分页获取留言
async function getMessage(params) {
	return await response.get('/api/message', {
		params: {
			page: 1,
			limit: 10,
			keyword: '',
			...params,
		},
	})
}

//  11-获取所有项目
async function getProject() {
	return await response.get('/api/project')
}

// 测试接口效果
// (async () => {
//     const resp = await getProject()
//     console.log(resp)
// })()

export default {
	getBanners,
	getSetting,
	getBlogtype,
	getBlog,
	getBlogId,
	postComment,
	getComment,
	getAbout,
	postMessage,
	getMessage,
	getProject,
}
