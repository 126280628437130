/**
 * 图片懒加载实现思路：
 * 1、利用自定义指令，存储所有图片的元素及图片真实地址
 * 2、先将所有图片的地址，改成默认图片
 * 3、单个处理图片：
 *      1、判断图片是否在视口内
 *      2、加载图片真实地址，真实地址加载完成后，将图片地址重新赋值
 *      3、完成赋值之后，将该图片信息从数组中移除，表示该图片元素已经处理，后续不用再次处理。
 */

import defaultImage from '@/assets/defaultImage.gif'
import eventBus from '@/eventBus'
import antishake from '@/Tools/antishake'

let imgs = []
eventBus.$on('mainScroll', antishake(setImages, 500))

function setImages() {
	if (!imgs.length) {
		return
	}
	//以下是有图片的情况
	for (const img of imgs) {
		setImage(img)
	}
}

//单个图片处理，如果在视口范围之内，则进行懒加载，如果不是，则显示默认图片
function setImage(img) {
	// 判断该元素是否在视口范围内
	const viewHeight = document.documentElement.clientHeight //视口高度
	const imgHeight = img.dom.clientHeight //图片高度
	const rect = img.dom.getBoundingClientRect() //图片的位置信息
	const diff = viewHeight - rect.top
	// diff>0则表示在视口范围之内
	if (diff > 0) {
		img.dom.src = img.src
		// 完成之后，从数组中删除该元素
		imgs = imgs.filter((item) => item != img)
	}
}

export default {
	inserted(el, bind) {
		//存储图片的相关信息
		const img = {
			dom: el, //图片元素
			src: bind.value, // 真正的图片地址
		}
		// 将该图片对象加入到图片数组中
		imgs.push(img)
		// 先显示默认图片
		el.src = defaultImage
		// 立即处理图片
		setImages()
	},
	//指令与元素解绑时，从图片数组中移除该图片元素
	unbind(el) {
		imgs = imgs.filter((img) => img.dom !== el)
	},
}
