<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-07-10 12:00:27
 * @LastEditors: wy
 * @LastEditTime: 2023-07-20 17:21:31
-->
<template>
    <div id="app">
        <layout>
            <template v-slot:left>
                <div class="left-nav">
                    <SiteAside></SiteAside>
                </div>
            </template>

            <template v-slot:main>
                <RouterView />
            </template>
        </layout>
        <toTop></toTop>
    </div>
</template>

<script>
    import layout from '@/components/layout';
    import SiteAside from '@/components/SiteAside';
    import Message from '@/components/Message';
    import toTop from './components/toTop';

    export default {
        name: 'App',
        components: {
            SiteAside,
            Message,
            layout,
            toTop,
        },
    };
</script>

<style lang="less" scoped>
    @import url('~@/styles/MainColor.less');
    @import url('~@/styles/PublicStyle.less');

    #app {
        .position-pull(fixed);
    }

    .left-nav {
        width: 250px;
        height: 100%;
        background-color: @dark;
        overflow-y: auto;
    }
</style>
