<!--
 * @Author: wy 3282268453@qq.com
 * @Date: 2023-07-10 16:55:26
 * @LastEditors: wy
 * @LastEditTime: 2023-07-18 15:35:00
 * @FilePath: \mine-site\src\components\Icont\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
    <i class="iconfont" :class="classMap"></i>
</template>

<script>
//字体图标映射表
const classMap = {
    arrowLeft: 'icon-jiantouzuo',
    arrowRight: 'icon-jiantouyou',
    arrowTop: 'icon-jiantoushang',
    arrowBottom: 'icon-jiantouxia',
    phone: 'icon-dianhua',
    network: 'icon-wangluo',
    mesg: 'icon-xinxi',
    email: 'icon-youxiang',
    QQ: 'icon-QQ',
    github: 'icon-github-fill',
    pen: 'icon-gangbi',
    house: 'icon-xiaofangzi',
    messageBoard: 'icon-liuyanban-05',
    weChat: 'icon-weixin',
    project: 'icon-xiangmu',
    empty: 'icon-empty',
    error: 'icon-error',
    info: 'icon-info',
    success: 'icon-success',
    close: 'icon-close-bold',
    warn: 'icon-warning-filling',
}

export default {
    props: {
        icont: {
            type: String,
            required: true,
        },
    },
    computed: {
        classMap() {
            return classMap[this.icont]
        },
    },
}
</script>

<style lang="less" scoped>
@import url('//at.alicdn.com/t/c/font_4160076_wtu6px6p97.css');
.iconfont {
    font-size: inherit;
}
</style>
