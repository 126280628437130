/**
 * 函数防抖
 * @param {Function} fn 要执行的函数
 * @param {Number} delayed 间隔时间
 */
export default function (fn, delayed = 100) {
    let timerId = null;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            fn(...args);
        }, delayed);
    };
}
