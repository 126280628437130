import Vue from 'vue';
import Vuex from 'vuex';

import setting from './modules/setting'; //全局设置
import banner from './modules/banner'; //首页
import about from './modules/about'; //关于我
import project from './modules/project';

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: true,
    modules: { banner, setting, about, project },
});

export default store;
