/*
 * @Descripttion:
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-07-10 12:00:27
 * @LastEditors: wy
 * @LastEditTime: 2023-07-20 12:07:35
 */
import Vue from 'vue'
import App from './App.vue' //导入启动vue
import '@/styles/common.less' //运行公共样式
import router from '@/router' //导入路由
import showMessage from '@/Tools/showMessage/showMessage' //工具：显示消息
// import '@/mock' //拦截请求，返回响应模拟数据
import '@/api' //提供远程请求/推送方法
import '@/eventBus'

//将showMessage 添加到Vue实例中
Vue.prototype.$showMessage = showMessage

//注册自定义指令
import vLoading from '@/directive/Loading'
import vLmageLazy from '@/directive/imageLazy'

Vue.directive('Loading', vLoading)
Vue.directive('imageLazy', vLmageLazy)

// 加入仓库管理
import store from '@/store'
store.dispatch('setting/getSetting')

// 挂载
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
