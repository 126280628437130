/* 指令配置对象说明
{
    bind(el,binding){
      // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
        
      // el 是被绑定元素对应的真实DOM
      // binding 是一个对象，描述了指令中提供的信息
    },
    inserted(el,binding){
      // 被绑定元素插入父节点时调用。
    },
    update(el,binding){
      // 所在组件的 VNode 更新时调用
    }
  }
*/
import loadSrc from '@/assets/loading.svg'
import style from './Loading.module.less'

function createLoadImg() {
	const img = document.createElement('img')
	img.src = loadSrc
	img.className = style.imgLoading
	img.dataset.imgloading = true
	return img
}

export default function (el, bind) {
	const curImg = el.querySelector('img[data-imgloading="true"]')
	if (bind.value) {
		if (!curImg) {
			const img = createLoadImg()
			el.appendChild(img)
		}
	} else {
		if (curImg) {
			curImg.remove()
		}
	}
}
