/*
 * @Descripttion: 
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-18 09:42:04
 * @LastEditors: wy
 * @LastEditTime: 2023-07-18 16:52:50
 */

import $ from "jquery"
import Message from './showMessage.module.less'
import Icont from '@/components/Icont'
import getComponentRootDom from '@/Tools/getComponentRootDom/getComponentRootDom'



/**
 * 弹出消息
 * @param {object} options :content 消息内容
 * 1.content: {string}  消息内容，默认值：'消息内容自定义'
 * 2.type: {string} 消息类型：info、error、success、warn;默认值:'info'
 * 3.container: {HTMLElement} DOM，将会把消息放在该容器的中间。默认值：'$("#app")[0]'
 * 4.duration： {Number} 消息弹窗显示时间。默认值：'3000'
 * 5.callback： {function} 消息弹窗结束后执行的回调函数，若没有传，则不执行。

 * @param {Number} duration 
 */

let playing = false

export default function (options) {
    const content = options.content || '消息内容自定义';
    const type = options.type || 'info'
    const container = options.container || $("#app")[0]
    const duration = options.duration || 3000
    if (playing) {
        return
    }
    playing = true
    const icont = getComponentRootDom(Icont, { icont: type })

    if (!container) {
        container = $("#app")[0]
    }

    if (getComputedStyle(container).position === 'static') {
        container.style.position = 'relative'
    }
    const messageHTML =
        `<div class="${Message.icont}">${icont.outerHTML}</div>
    <div class="content">${content}</div>`

    const div = $("<div></div>")
        .addClass(`${Message.message} ${Message['message-' + type]} `)
        .html(messageHTML)
        .appendTo(container);

    // 触发refllow
    div[0].clientHeight

    // 以过渡效果，回归到本来的位置
    div[0].style.transform = 'translate(-50%, -50%)'
    div[0].style.opacity = 1
    setTimeout(() => {
        div[0].style.transform = 'translate(-50%, -50%) translateY(-20px)'
        div[0].style.opacity = 0

        div.on('transitionend', (() => {
            div.remove()
            playing = false
            options.callback && options.callback()
        }))
    }, duration);

}