let routerTitle = '',
    websiteTitle = '';

function setTitle() {
    if (!routerTitle && !websiteTitle) {
        document.title = 'loading...';
    } else if (routerTitle && !websiteTitle) {
        document.title = routerTitle;
    } else if (!routerTitle && websiteTitle) {
        document.title = websiteTitle;
    } else {
        document.title = `${routerTitle}-${websiteTitle}`;
    }
}

export default {
    // 设置路由标题
    setRouterTitle(title) {
        routerTitle = title;
        setTitle();
    },
    // 设置网站标题
    setWebsiteTitle(title) {
        websiteTitle = title;
        setTitle();
    },
};
