/*
 * @Descripttion: 获取某个组件的真实Dom
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-18 11:09:47
 * @LastEditors: wy
 * @LastEditTime: 2023-07-20 15:41:03
 */


import Vue from 'vue'
/**
 * 得到组件渲染的Dom
 * @param {Vue} comp 
 * @param {Array} props 
 * @returns DOM元素
 */
export default function (comp, props) {
    const vm = new Vue({
        render: h => h(comp, { props })
    })
    vm.$mount();
    return vm.$el;
}