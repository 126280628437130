/*
 * @Descripttion:
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-19 17:57:56
 * @LastEditors: wy
 * @LastEditTime: 2023-07-20 12:13:38
 */
import axios from 'axios'
import showMessage from '@/Tools/showMessage/showMessage'

const response = axios.create()
response.interceptors.response.use(function (resp) {
    if (resp.data.code !== 0) {
        showMessage({
            content: resp.data.msg,
            type: 'error',
            duration: 3000,
        })
        return null
    }
    return resp.data
})

export default response
