<template>
    <div
        class="toTop-container"
        v-show="isShow"
        @click="handleClick"
    >
        TOP
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isShow: false,
            };
        },
        methods: {
            handleScroll(dom) {
                if (!dom) {
                    //该dom不存在了
                    this.isShow = false;
                    return;
                }
                this.isShow = dom.scrollTop >= window.innerHeight;
                return;
            },
            // 处理点击事件
            handleClick() {
                this.$bus.$emit('setMainScroll', 0);
            },
        },
        created() {
            this.$bus.$on('setMainScroll', this.handleScroll);
        },
        destroyed() {
            this.$bus.$off('setMainScroll', this.handleScroll);
            this.isShow = false;
        },
    };
</script>

<style lang="less" scoped>
    @import url('~@/styles/MainColor.less');
    .toTop-container {
        @size: 50px;
        width: @size;
        height: @size;
        background-color: @primary;
        color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 99;
        right: 30px;
        bottom: 30px;
        cursor: pointer;
    }
</style>
