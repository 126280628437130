//首页数据
import api from '@/api'
// import { BASE_URL } from '@/urlConfig'
export default {
	namespaced: true,
	state: {
		isLoading: false,
		data: [],
	},
	mutations: {
		setIsLoading(state, payload) {
			state.isLoading = payload
		},
		setData(state, payload) {
			state.data = payload
		},
	},
	actions: {
		async getBanners(context) {
			if (context.state.data.length) {
				return
			}
			context.commit('setIsLoading', true)
			const resp = await api.getBanners()
			// const bannerData = resp.data.map((i) => {
			// 	return {
			// 		...i,
			// 		showMidImg: BASE_URL + i.midImg,
			// 		showBigImg: BASE_URL + i.bigImg,
			// 	}
			// })
			const bannerData = resp.data
			context.commit('setData', bannerData)
			context.commit('setIsLoading', false)
			return resp
		},
	},
}
