//手写  事件总线
// let eventLists = {}; //事件总线总览
// export default {
//     // 监听某一个事件
//     $on(eventName, handler) {
//         if (!eventLists[eventName]) {
//             eventLists[eventName] = new Set();
//         }
//         eventLists[eventName].add(handler);
//     },
//     // 取消监听某个事件
//     $off(eventName, handler) {
//         if (!eventLists[eventName]) {
//             return;
//         }
//         eventLists[eventName].delete(handler);
//     },
//     // 触发某个事件
//     $emit(eventName, ...args) {
//         if (!eventLists[eventName]) {
//             return;
//         }
//         for (const handler of eventLists[eventName]) {
//             handler(...args);
//         }
//     },
// };

/**
 * 1.事件名：mainScroll
 * 含义：主区域滚动条位置变化后触发
 * 参数：
 *  -滚动的DOM元素，如果是undefined，则说明该元素不存在
 *
 *
 * 2.事件名：setMainScroll
 * 含义：设置主区域滚动条的位置
 * 参数:    number 滚动条的位置。
 */

//vue 实例自带 事件总线
import Vue from 'vue';
const app = new Vue();

//在Vue的隐式原型里添加事件总线
Vue.prototype.$bus = app;

export default app;
