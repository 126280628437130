<!--
 * @Descripttion: 
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-13 17:37:01
 * @LastEditors: wy
 * @LastEditTime: 2023-07-17 15:57:34
-->
<template>
    <div
        class="SiteAside-container"
        v-if="!isLoading"
    >
        <HeadImg
            :url="data.avatar"
            :size="150"
            :title="data.siteTitle"
        ></HeadImg>

        <div class="Menu-container">
            <Menu></Menu>
        </div>

        <div class="Contact-container">
            <Contact></Contact>
        </div>
    </div>
</template>

<script>
    //导入子组件
    import HeadImg from '@/components/HeadImg';
    import Contact from './subassembly/Contact';
    import Menu from './subassembly/Menu';
    import { mapState } from 'vuex';

    export default {
        components: {
            HeadImg,
            Contact,
            Menu,
        },
        computed: mapState('setting', ['data', 'isLoading']),
    };
</script>

<style lang="less" scoped>
    @import url('~@/styles/MainColor.less');
    @import url('~@/styles/PublicStyle.less');

    .SiteAside-container {
        width: 100%;
        height: 100%;
        .flex-direction(@r-gap: 20px; );
        justify-content: space-evenly;
        align-items: center;
        color: @gray;
    }
</style>
