import api from '@/api';

export default {
    namespaced: true,
    state: {
        isLoading: false,
        data: [],
    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload;
        },
        setData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getAbout(context) {
            if (context.state.data.length) {
                return;
            }
            context.commit('setIsLoading', true);
            const resp = await api.getAbout();
            context.commit('setData', resp.data);
            context.commit('setIsLoading', false);
            return resp;
        },
    },
};
