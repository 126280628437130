/*
 * @Descripttion: 
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-19 17:45:54
 * @LastEditors: wy
 * @LastEditTime: 2023-07-20 09:43:54
 */

import axios from 'axios'
import showMessage from '@/Tools/showMessage/showMessage'

const request = axios.create()
request.interceptors.request.use(function (config) {
    //请求拦截器
    return resp.data
}, function name (error) {

    return Promise.reject(error) //返回拒绝状态的任务
})


export default request