// 全局设置
import api from '@/api'
import titleControl from '@/Tools/titleControl'
// import { BASE_URL } from '@/urlConfig'
export default {
	namespaced: true,
	state: {
		isLoading: false,
		data: null,
	},
	mutations: {
		setIsLoading(state, payload) {
			state.isLoading = payload
		},
		setData(state, payload) {
			state.data = payload
		},
	},
	actions: {
		async getSetting(context) {
			if (context.state.data) {
				return
			}
			context.commit('setIsLoading', true)
			const { data } = await api.getSetting()
			//网站图标处理
			//<link rel="shortcut icon" href="favicon.ico" type="image/x-icon">

			const linkFavicon = document.querySelector('link[rel="shortcut icon"]')

			if (!linkFavicon) {
				const link = document.createElement('link')
				link.rel = 'shortcut icon'
				link.type = '"image/x-icon"'
				link.href = data.favicon
				document.querySelector('head').appendChild(link)
			}
			//数据处理
			const setting = {
				...data,
				// qqQrCode: BASE_URL + data.qqQrCode,
				// weixinQrCode: BASE_URL + data.weixinQrCode,
				// avatar: BASE_URL + data.avatar,
			}
			context.commit('setData', setting)
			context.commit('setIsLoading', false)
			if (data.siteTitle) {
				titleControl.setWebsiteTitle(data.siteTitle)
			}
			return data
		},
	},
}
