/*
 * @Descripttion:
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-17 16:14:12
 * @LastEditors: wy
 * @LastEditTime: 2023-07-17 16:40:28
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/router';
import titleControl from '@/Tools/titleControl';
// 开启控制台提示的开关
// Vue.config.productionTip = false

//使用一个vue插件
Vue.use(VueRouter);

//创建路由对象
const router = new VueRouter({
    // 路由配置
    routes,
    /**路由模式 mode
     * 1.hash：默认值，路由从浏览器location.hash获取路径，并且更改的也是它，改变hash不会导致浏览器刷新，该模式的兼容性最好。
     *
     * 2.history：路由从浏览器地址栏的`location.pathname`中获取路径，改变路径使用的 H5 的`history api`。该模式可以让地址栏最友好，但是需要浏览器支持`history api`；在模版(template) 中，使用<RouterLink> 标签替换 a元素，可以不刷新页面。
     *
     * 3.abstract：路由从内存中获取路径，改变路径也只是改动内存中的值。这种模式通常应用到非浏览器环境中。一般是手机使用该模式
     */
    mode: 'history',
});

// 全局后置守卫
router.afterEach((to, from) => {
    if (to.meta.title) {
        titleControl.setRouterTitle(to.meta.title);
    }
});

export default router;
