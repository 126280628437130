<!--
 * @Descripttion: 
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-17 14:47:49
 * @LastEditors: wy
 * @LastEditTime: 2023-07-17 15:42:01
-->
<template>
    <div class="layout-container">
        <div class="layout-left">
            <slot name="left"></slot>
        </div>
        <div class="layout-main">
            <slot name="main"></slot>
        </div>
        <div class="layout-right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="less" scoped>
    @import url('~@/styles/MainColor.less');
    @import url('~@/styles/PublicStyle.less');

    .layout-container {
        width: 100%;
        height: 100%;
        display: flex;

        .layout-left,
        .layout-right {
            flex: 0 0 auto;
            overflow: hidden;
        }

        .layout-main {
            flex: 1 1 auto;
            overflow: hidden;
        }
    }
</style>
