<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-07-10 14:19:30
 * @LastEditors: wy
 * @LastEditTime: 2023-07-17 14:25:15
-->
<template>
	<div class="Head-container">
		<img
			class="Head-img"
			:src="url"
			:style="{ width: `${size}px`, height: `${size}px` }"
		/>
		<span>{{ title }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			url: {
				type: String,
				required: true,
			},
			size: {
				type: Number,
				default: 100,
			},
			title: {
				type: String,
				default: '王启华的标题',
			},
		},
		computed: {
			getTitle() {
				return this.title
			},
		},
	}
</script>

<style lang="less" scoped>
	@import url('~@/styles/PublicStyle.less');
	.Head-container {
		.flex-direction(@r-gap:20px;);
		align-items: center;
		.Head-img {
			border-radius: 50%;
			object-fit: cover;
			display: block;
		}
		span {
			font-weight: 600;
			color: #fff;
		}
	}
</style>
