<!--
 * @Descripttion: 
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-13 11:26:15
 * @LastEditors: wy
 * @LastEditTime: 2023-07-17 11:07:47
-->

<template>
    <div class="Contact-container">
        <!-- github -->
        <div class="item">
            <div class="center">
                <Icont :icont="'github'"></Icont>
                <a
                    class="text"
                    :href="data.github"
                    target="_blank"
                    >{{ data.githubName }}</a
                >
            </div>
            <div
                class="popup"
                v-if="data.gitCode"
            >
                <img :src="data.gitCode" />
            </div>
        </div>

        <!-- email -->
        <div class="item">
            <div class="center">
                <Icont :icont="'email'"></Icont>
                <a
                    class="text"
                    :href="`mailto:${data.mail}`"
                    target="_blank"
                    >{{ data.mail }}</a
                >
            </div>
        </div>

        <!-- QQ -->
        <div class="item">
            <div class="center">
                <Icont :icont="'QQ'"></Icont>
                <a
                    class="text"
                    :href="`tencent://message/?Menu=yes&uin=${data.qq}`"
                    target="_blank"
                    >{{ data.qq }}</a
                >
            </div>
            <div
                class="popup"
                v-if="data.qqQrCode"
            >
                <img :src="data.qqQrCode" />
            </div>
        </div>

        <!-- 微信 -->
        <div class="item">
            <div class="center">
                <Icont :icont="'weChat'"></Icont>
                <a
                    class="text"
                    target="_blank"
                    >{{ data.weixin }}</a
                >
            </div>
            <div
                class="popup"
                v-if="data.weixinQrCode"
            >
                <img :src="data.weixinQrCode" />
            </div>
        </div>

        <div class="tip">
            <span>{{ data.icp }}</span>
        </div>
    </div>
</template>

<style lang="less" scoped>
    @import url('~@/styles/MainColor.less');

    .Contact-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 10px;
        .item {
            position: relative;
            .center {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .iconfont {
                    color: inherit;
                    margin-right: 10px;
                    font-size: 18px;
                }
                &:hover {
                    color: @primary;
                    cursor: pointer;
                }
                &:hover ~ .popup {
                    transform: scaleY(1);
                }
            }
            .popup {
                @size: 150px;
                width: @size;
                height: @size;
                padding: 10px;
                background: #fff;
                border: 1px solid @dark;
                border-radius: 5px;
                position: absolute;
                left: 0;
                bottom: 25px;
                transition: all 0.3s;
                transform: scaleY(0);
                transform-origin: center bottom;
                &::after {
                    @size: 10px; //小方角的边长
                    content: '';
                    width: @size;
                    height: @size;
                    background: inherit;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%, (@size / 2)) rotate(45deg);
                }
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .tip {
            margin: 20px auto;
            color: @lightWords;
            font-size: 12px;
        }
    }
</style>

<script>
    import Icont from '@/components/Icont';
    import { mapState } from 'vuex';
    export default {
        components: {
            Icont,
        },
        computed: mapState('setting', ['data', 'isLoading']),
        data() {
            return {
                ContactData: [
                    {
                        id: 4,
                        icont: 'weChat',
                        text: 'wangqihuawechat',
                        imgSrc: 'https://img2.baidu.com/it/u=2251133135,1244114090&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=1000',
                    },
                ],
            };
        },
    };
</script>
