<!--
 * @Descripttion: 
 * @Version: 5.0.8
 * @Author: wy
 * @Date: 2023-07-17 13:51:35
 * @LastEditors: wy
 * @LastEditTime: 2023-07-17 14:42:33
-->
<template>
    <div class="Message-container">
        <div class="content">
            <slot></slot>
        </div>
        <div class="btn">
            <button>确定</button>
            <button>取消</button>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@import url('~@/styles/PublicStyle.less');
@import url('~@/styles/MainColor.less');
.Message-container {
    border: 1px @gray;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px @gray;
    .position-center();
    .flex-direction(@r-gap:20px);
    padding: 10px;
    background: darken(@primary, 0%);
    color: #fff;
    .btn {
        width: 100%;
        .flex-direction(@type:row;@c-gap:10px;);
        justify-content: right;
        button {
            padding: 5px;
            border: 1px solid;
            box-shadow: 0 0 1px 1px;
            cursor: pointer;
            &:hover {
                background: @gray;
            }
        }
    }
}
</style>
